import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { Box, CircularProgress, Divider, Drawer, Grid, Toolbar, Typography, Paper } from '@mui/material';
import Button from '@mui/material/Button';

import { setChapters, setConcepts, setDifficulty, setExam, setPYQs, setQuestionType, setScenarios, setSource, setSubject, setSubtopics, setTopics } from '../../store/slice/filterSlice';
import { removeAllRejectedQuestions } from '../../store/slice/rejectSlice';

import StaticFilterComponent from '../../components/StaticFilter';
import ProblemStatement from '../../components/ProblemStatement';
import Pagination from '../../components/Pagination';
import QuizSelector from '../../components/QuizSelector';
// import { uploadQuestionsToDrive } from '../../utils/question';
import { clearSelection, removeActiveQuiz } from '../../store/slice/quizSlice';
import { fetchQuizByStudentID } from '../../utils/quiz';

const QuestionManager = () => {
    const dispatch = useDispatch();

    const token = useSelector((state) => state.user.accessToken);
    const activeQuiz = useSelector((state) => state.quiz.activeQuiz);

    const [questions, setQuestions] = useState([]);
    const [openQuizModal, setOpenQuizModal] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    const limit = 20;
    
    const selectedExam = useSelector((state) => state.filters.exam);
    const selectedSubject = useSelector((state) => state.filters.subject);
    const selectedChapter = useSelector((state) => state.filters.chapters);
    const selectedTopic = useSelector((state) => state.filters.topics);
    const selectedSource = useSelector((state) => state.filters.source);
    const selectedSubTopic = useSelector((state) => state.filters.subtopics);
    const selectedQuestionType = useSelector((state) => state.filters.question_type);
    const selectedDifficulty = useSelector((state) => state.filters.difficulty);
    const selectedConcepts = useSelector((state) => state.filters.concepts);
    const selectedPYQs = useSelector((state) => state.filters.pyqyear);
    const selectedScenarios = useSelector((state) => state.filters.scenarios);

    const recover = useSelector((state) => state.rejectQuestion.removedQuestions || []);
    const allRejectedQuestions = useSelector((state) => state.rejectQuestion.removedQuestions);

    const [conceptList, setConceptList] = useState([]);
    const [scenarioList, setScenarioList] = useState([]);

    const [pyqYearList, setPYQYearList] = useState([]);
    const [subtopicList, setSubTopicList] = useState([]);

    const [studentQuizzes, setStudentQuizzes] = useState([])
    const [message, setMessage] = useState({})
    
    const recoverRemovedQuestions = () => {
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions())
        }
    };

    useEffect(() => {
        if (recover.length === 0) {
            if (questions.length > 0)
                fetchQuestions(page)
        }
    }, [recover])

    useEffect(() => {
        if (activeQuiz.saved) {
            dispatch(removeActiveQuiz())
            dispatch(clearSelection({}))
        }
    }, [activeQuiz])

    useEffect(() => { 
        try {
            const fetchStudentsQuiz = async (studentIds) => {
                setIsLoading(true)
                const response = await fetchQuizByStudentID(token, studentIds)
                setStudentQuizzes(response)

            }

            if (activeQuiz.studentId && activeQuiz.studentId.length > 0)
                fetchStudentsQuiz(activeQuiz.studentId)
            else {
                setStudentQuizzes([])
                setMessage([])
            }
                


        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }, [activeQuiz, token])

    const resetFilters = () => {
        dispatch(setExam(''));
        dispatch(setSubject(''));
        dispatch(setChapters([]));
        dispatch(setTopics([]));
        dispatch(setSubtopics([]));
        dispatch(setSource(''));
        dispatch(setQuestionType(''));
        dispatch(setDifficulty([]));
        dispatch(setConcepts([]));
        dispatch(setScenarios([]));
        dispatch(setPYQs([]));

        setConceptList([]);
        setScenarioList([]);
        setPYQYearList([]);
        setSubTopicList([]);
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions());
        }
    };

    const fetchQuestions = async (pageNumber = 0) => {
        setIsLoading(true);
        if (selectedTopic.length === 0) {
            alert("Please select a topic");
        } else {
            let queryPage = 1
            if (pageNumber === 0) {
                queryPage = 1;
            } else {
                queryPage = pageNumber
            }
            
            const payload = {
                exam: selectedExam,
                subject: selectedSubject,
                chapter: selectedChapter,
                topic: selectedTopic,
                source: selectedSource,
                subtopic: selectedSubTopic,
                questiontype: selectedQuestionType,
                difficulty: selectedDifficulty,
                concepts: selectedConcepts,
                scenarios: selectedScenarios,
                pyqyears: selectedPYQs,
                rejectedQuestions: recover,  // Add this line to include rejected question IDs
                page: queryPage,
                limit: limit
            };

            try {
                const response = await axios.get('/api/questions/fetchQuestions', {
                    params: payload,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const responseData = await response.data;
                
                setQuestions(responseData.questions);
                
                if (conceptList.length === 0) {
                    setConceptList(responseData.concepts)
                }

                if (pyqYearList.length === 0) {
                    setPYQYearList(responseData.pyqyears)
                }

                if (responseData.pyqyears.length === 0) {
                    setPYQYearList([]);
                }

                setSubTopicList(responseData.subtopics)

                if (responseData.subtopics.length === 0) {
                    setSubTopicList([]);
                }

                if (responseData.concepts.length === 0) {  
                    setConceptList([])
                    setScenarioList([])
                    dispatch(setConcepts([]))
                    dispatch(setScenarios([]))
                }

                if (responseData.scenarios.length > 0)
                    setScenarioList(responseData.scenarios)
                
                setTotal(responseData.total);
                setPage(responseData.page);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        }
        setIsLoading(false);
    };
    
    
    const onButtonClick = async () => {
        if (recover.length > 0) {
            dispatch(removeAllRejectedQuestions())
        }
        await fetchQuestions(0)
    };

    useEffect(() => {
        if (activeQuiz.studentId && studentQuizzes.length > 0) {
          const testedQuestions = {}
          const studentNames = activeQuiz.studentName
          const studentIds = activeQuiz.studentId
  
          studentQuizzes.map(quiz => {
            if (studentIds.includes(quiz.student_id)){
              const intersection = questions.filter(value => quiz.questions.includes(value.id))
              
              if (!Object.keys(testedQuestions).includes(quiz.student_name)) {
                testedQuestions[quiz.student_name] = {}
              }
  
              if (intersection.length > 0) {
                if (Object.keys(testedQuestions).includes(quiz.student_name) && Object.keys(testedQuestions[quiz.student_name]).includes(quiz.name))
                  testedQuestions[quiz.student_name][quiz.name] = testedQuestions[quiz.student_name][quiz.name].concat(intersection.map(q => q.id))
                else
                  testedQuestions[quiz.student_name][quiz.name] = intersection.map(q => q.id)
              }
            }
            return {}
          })
          
          studentNames.map((name) => {
            return Object.values(testedQuestions[name]).map((quiz) => {
              testedQuestions[name][quiz] = [...new Set(testedQuestions[name][quiz])]
              return {}
            })
          })
          
          setMessage(testedQuestions)
        }
      }, [questions, studentQuizzes, activeQuiz])


    if (isFilterLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Toolbar />
                <Toolbar />
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 0, maxWidth: '75vw', bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <StaticFilterComponent 
                    selectedExam={selectedExam}
                    selectedSubject={selectedSubject}
                    selectedChapter={selectedChapter}
                    selectedTopic={selectedTopic}
                    selectedSource={selectedSource}
                    selectedSubTopic={selectedSubTopic}
                    selectedQuestionType={selectedQuestionType}
                    selectedDifficulty={selectedDifficulty}
                    onButtonClick={onButtonClick}
                    resetFilters={resetFilters}
                    setIsFilterLoading={setIsFilterLoading}
                    selectedConcepts={selectedConcepts}
                    selectedPYQs={selectedPYQs}
                    conceptList={conceptList}
                    selectedScenarios={selectedScenarios}
                    scenarioList={scenarioList}
                    pyqList={pyqYearList}
                    subtopicList={subtopicList}
                />
                <Pagination fetchQuestions={fetchQuestions} page={page} limit={limit} total={total} />
                <Grid container className='questionContainer'>
                    {!isLoading && questions.map((questionData) => (
                        !allRejectedQuestions.includes(questionData['id']) && 
                            <Box key={questionData['id']} sx={{ width: '100%' }}>
                                <ProblemStatement 
                                    id={questionData['id']}
                                    data={questionData}
                                    displayQuestion={true}
                                    displayQuestionId={true}
                                    displaySolution={true}
                                    displayAnswerKey={true}
                                    displayOptions={true}
                                    displayAddToQuiz={true}
                                    displayRemoveFromQuiz={true}
                                    displayConcepts={true}
                                    displayRejectQuestion={true}
                                    setOpenQuizModal={setOpenQuizModal}
                                />
                                {
                                    !activeQuiz.saved &&
                                    <Box sx={{
                                        marginBottom: '1vh',
                                        width: '100%', 
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {
                                        Object.entries(message).length > 0 &&
                                            Object.entries(message).map(([name, quizzes]) => (
                                            Object.entries(quizzes).map(([quizname, questions], index) => (
                                                questions.includes(questionData['id']) &&
                                                <Paper 
                                                key={index}
                                                elevation={0}
                                                sx={{
                                                    paddingLeft: '2vh',
                                                    paddingBottom: '1vh',
                                                    paddingTop: '1vh',
                                                    borderRadius: 0,
                                                }}
                                                >
                                                <Typography variant="body2">
                                                    <span key={index}>This question has already been tested for: <b>{name}</b> in quiz <b>{quizname}</b></span>
                                                </Typography>
                                                </Paper>
                                            ))
                                        ))
                                        }
                                    </Box>
                                    }
                                <Divider style={{ backgroundColor: 'black' }} />
                            </Box>
                    ))}
                    {isLoading && <CircularProgress />}
                </Grid>
                <Pagination fetchQuestions={fetchQuestions} page={page} limit={limit} total={total} />
            </Box>
            <Drawer
                sx={{ width: '20vw', flexShrink: 0, '& .MuiDrawer-paper': { width: '20vw', boxSizing: 'border-box' } }}
                variant="permanent"
                anchor="right"
            >
                <Toolbar />
                {
                    recover.length > 0 && 
                    <Button 
                        color="primary" 
                        size='small' 
                        sx={{
                            marginTop: '2vh',
                            marginLeft: '2vh',
                            marginRight: '2vh'
                        }}
                        variant="contained" 
                        onClick={recoverRemovedQuestions}>
                            Reset Rejected Questions ({`${recover.length}`})
                    </Button>
                }
                {/* <Button onClick={questionUpload}>Test Qestion Upload</Button> */}
                <QuizSelector openQuizModal={openQuizModal} setOpenQuizModal={setOpenQuizModal} />
                
            </Drawer>
        </Box>
    );
};

export default QuestionManager;
