import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import SingleSelectInput from './selectComponents/singleSelectInput'
import MultiSelectInput from './selectComponents/multiSelectInput'

import { setChapters, setDifficulty, setExam, setQuestionType, setSource, setSubject, setSubtopics, setTopics, setConcepts, setScenarios, setPYQs} from '../store/slice/filterSlice'
const StaticFilterComponent = ({ 
    selectedExam,
    selectedSubject,
    selectedChapter,
    selectedTopic,
    selectedSubTopic,
    selectedSource,    
    selectedQuestionType,        
    selectedDifficulty,        
    onButtonClick,
    resetFilters,
    setIsFilterLoading,
    selectedConcepts,
    selectedScenarios,
    selectedPYQs,
    conceptList = [],
    scenarioList = [],
    pyqList = [],
    subtopicList = []
}) => {
    const token = useSelector((state) => state.user.accessToken)
    const dispatch = useDispatch()

    const [filters, setFilters] = useState({
        sources: [],
        exams: [],
        subjects: [],
        chapters: [],
        topics: [],
        subtopic: [],
        question_type: [],
        difficulty: []
    })


    useEffect(() => {
        // Fetch Filter Configuration From DB
        const fetchFilters = async () => {
          
          try {
            const response = await axios.get('/api/questions/fetchFilters', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }) // Replace with your API endpoint
            setIsFilterLoading(true)
            const data = await response.data.filters
            setFilters(data)
            setIsFilterLoading(false)
          } catch (error) {
            console.error('Error fetching filters:', error)
          }
          
        }
        
        fetchFilters()
    }, [token])

    const handleSourceChange = (event) => {
        dispatch(setSource(event.target.value))
        dispatch(setExam(''))
        dispatch(setSubject(''))
        dispatch(setChapters([]))
        dispatch(setTopics([]))
        dispatch(setSubtopics([]))
        dispatch(setScenarios([]))
        dispatch(setConcepts([]))
        dispatch(setQuestionType(''))
    }

    const handleExamChange = (event) => {
        dispatch(setExam(event.target.value))
        dispatch(setSubject(''))
        dispatch(setChapters([]))
        dispatch(setTopics([]))
        dispatch(setSubtopics([]))
        dispatch(setScenarios([]))
        dispatch(setConcepts([]))
    }

    const handleSubjectChange = (event) => {
        dispatch(setSubject(event.target.value))
        dispatch(setChapters([]))
        dispatch(setTopics([]))
        dispatch(setSubtopics([]))
        dispatch(setScenarios([]))
        dispatch(setConcepts([]))
    }

    const handleQuestioTypeChange = (event) => {
        dispatch(setQuestionType(event.target.value))
    }

    const handleChapterChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setChapters(value))
        dispatch(setTopics([]))
        dispatch(setSubtopics([]))
    }

    const handleTopicChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setTopics(value))
        dispatch(setSubtopics([]))
    }

    const handleSubTopicChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setSubtopics(value))
    }
    
    const handleDifficultyChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setDifficulty(value))
    }

    const handleConceptChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setConcepts(value))
    }

    const handlePyqChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setPYQs(value))
    }

    const handleScenarioChange = (event) => {
        const {
            target: { value },
        } = event

        dispatch(setScenarios(value))
    }

    return (
        <div className='filter-wrapper'>
            {
                filters.exams.length > 0 && 
                <div className="filter-container">
                    <div>
                        <label>Source</label>
                        <SingleSelectInput 
                            selectedValue={selectedSource}
                            handleChange={handleSourceChange}
                            options={filters.sources}
                        />
                    </div>
                    <div>
                        <label>Exam</label>
                        <SingleSelectInput 
                            selectedValue={selectedExam}
                            handleChange={handleExamChange}
                            options={
                                filters.exams
                                .filter(exam => exam.source === selectedSource)
                            }
                        />
                    </div>
                    <div>
                        <label>Subject</label>
                        <SingleSelectInput 
                            selectedValue={selectedSubject}
                            handleChange={handleSubjectChange}
                            options={filters.subjects
                                .filter(subject => subject.exam === selectedExam)}
                        />
                    </div>
                    <div>
                        <label>Chapter</label>
                        <MultiSelectInput 
                            selectedValues={selectedChapter}
                            handleChange={handleChapterChange}
                            options={filters.chapters
                                .filter(chapter => chapter.subject === selectedSubject && chapter.exam === selectedExam)}
                        />
                    </div>
                </div>
            }
            {
                filters.exams.length > 0 &&
                <div className="filter-container">
                    <div>
                        <label>Topic</label>
                        <MultiSelectInput 
                            selectedValues={selectedTopic}
                            handleChange={handleTopicChange}
                            options={filters.topics
                                .filter(topic => topic.subject === selectedSubject && topic.exam === selectedExam && selectedChapter.includes(topic.chapter))}
                        />
                    </div>
                    
                    <div>
                        <label>Sub Topic</label>
                        <MultiSelectInput 
                            selectedValues={selectedSubTopic}
                            handleChange={handleSubTopicChange}
                            options={subtopicList}
                        />
                    </div>

                    <div>
                        <label>Difficulty</label>
                        <MultiSelectInput 
                            selectedValues={selectedDifficulty}
                            handleChange={handleDifficultyChange}
                            options={filters.difficulty}
                        />
                    </div>
                    <div>
                        <label>Question Type</label>
                        <SingleSelectInput 
                            selectedValue={selectedQuestionType}
                            handleChange={handleQuestioTypeChange}
                            options={filters.question_type
                                .filter(qtype => qtype.source === selectedSource)}
                        />
                    </div>
                </div>
            }
            <div className='filter-container'>
            {
                // conceptList.length > 0 &&
                <div>
                    <label>Select Concept</label>
                    <MultiSelectInput 
                        selectedValues={selectedConcepts}
                        handleChange={handleConceptChange}
                        options={conceptList}
                    />
                </div>
            }
            {
                // scenarioList.length > 0 &&
                <div>
                    <label>Select Scenario</label>
                    <MultiSelectInput 
                        selectedValues={selectedScenarios}
                        handleChange={handleScenarioChange}
                        options={scenarioList}
                    />
                </div>
            }

                <div>
                    <label>PYQ Year</label>
                    <MultiSelectInput 
                        selectedValues={selectedPYQs}
                        handleChange={handlePyqChange}
                        options={pyqList}
                    />
                </div>
            </div>
            
            

            <Stack spacing={2} direction="row" className='buttonGroup'>
                <Button variant="contained" onClick={onButtonClick}>Search</Button>
                <Button variant="contained" onClick={resetFilters}>Reset Filters</Button>
            </Stack>
        </div>
    )
}

export default StaticFilterComponent
