import { useEffect } from "react"

const Question = ({data, index}) => {
    useEffect(()=>{
        if (window.MathJax){
            window.MathJax.startup.promise.then(() => {
              window.MathJax.typesetClear();
              window.MathJax.typeset();
            });
        }
      },[])
    return (
        <div style={{width:'100%'}} className="questionItem">
            <span>
                <b>{index}. Question</b>
            </span>
            {
                data.question_latex === '' ?
                <div className="questionContent" style={{width:'100%'}}>
                    <img style={{width:'100%', objectFit:'contain'}}
                        src={data.question_image}
                        alt={data.question_image}
                    />
                </div> 
                :
                <div className="questionContent">
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.question_latex
                            }}
                        />
                    </div>
                    {
                        data.supporting_data_latex === '' ?
                        <div>
                            <img 
                                src={data.supporting_data_image}
                                alt={data.supporting_data_image}
                                style={{margin: '1vh', maxWidth: '60%'}}
                            />
                        </div>
                        :
                        <span>{data.supporting_data_latex}</span>
                    }
                </div>
            }
        </div>
    )
}


export default Question