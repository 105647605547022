import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Toolbar,
  Typography,
  Stack,
  Button,
  Backdrop,
  Snackbar,
} from "@mui/material";
import ProblemStatement from "../../components/ProblemStatement";
import Solution from "../../components/problemStatementComponents/Solution";
import FixedSideNavigation from "../../components/FixedSideNavigation";

import {
  fetchQuestionById,
  uploadQuestionsToDrive,
} from "../../utils/question";
import {
  fetchQuizByID,
  downloadQuizSolution,
  markQuizAsExported,
  exportQuestionsToClassMarker,
  downloadQuizPaper,
} from "../../utils/quiz";

import axios from "axios";
import ErrorBoundary from "../../components/ErrorBoundary";
import AnswerKey from "../../components/problemStatementComponents/AnswerKey";

const QuizViewer = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.accessToken);

  const [quizData, setQuizData] = useState({});
  const [singleQuiz, setSingleQuiz] = useState({});

  const [questionDetails, setQuestionDetails] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [message, setMessage] = useState("");
  const [selectStudent, setSelectedStudent] = useState(null);
  const [student_Classmarker_id, setStudent_Classmarker_id] = useState();

  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const [quizMeta, setQuizMeta] = useState({});
  const closeSnackbar = () => {
    setSnackBar(false);
    setSnackbarMessage("");
  };

  const handleClose = () => {
    setBackDrop(false);
  };
  const handleOpen = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const fetchQuiz = async () => {
      const quiz = await fetchQuizByID(token, id);
      if (quiz.length > 0) {
        setQuizMeta({
          exporedAt: quiz[0].exported_at,
          classmarkerName: quiz[0].classmarker_category,
          subject: quiz[0].subject,
          difficulty: quiz[0].difficulty,
          topic: quiz[0].topic,
          chapter: quiz[0].chapter,
          max_attempt_time_in_minutes: quiz[0].max_attempt_time_in_minutes,
        });
        setQuizData(quiz[0]);
      }
    };
    fetchQuiz();
  }, [id, token]);

  useEffect(() => {
    const fetchQuestions = async () => {
      setIsLoading(true);
      const category_id = quizData.classmarker_category_id;
      let fetchedDetails = await Promise.all(
        quizData.questions.map((id) =>
          fetchQuestionById({ id, token, category_id: category_id })
        )
      );

      fetchedDetails = fetchedDetails.sort(
        (a, b) => a.classmarker_id - b.classmarker_id
      );

      setQuestionDetails(fetchedDetails);
      setIsLoading(false);
    };

    if (Object.entries(quizData).length > 0) {
      fetchQuestions();
    }
  }, [quizData, token]);

  const downloadSolution = async () => {
    if (!buttonLoading) {
      setButtonLoading(true);
      const name = `${quizData.name} - ${quizData.student_name.join(", ")}`;
      await downloadQuizSolution(token, id, name);
      setButtonLoading(false);
    }
  };

  const downloadPaper = async () => {
    if (!buttonLoading) {
      setButtonLoading(true);
      const name = `${quizData.name} - ${quizData.student_name.join(", ")}`;
      await downloadQuizPaper(token, id, name);
      setButtonLoading(false);
    }
  };

  const exportQuestions = async () => {
    handleOpen();
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled by the user.");
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    const filteredList = questionDetails
      .filter((ques) => ["ideaboard", 'exam-side'].includes(ques.source))
      .map((ques) => ques.id);

    setMessage("Uploading Question Images...");
    const success = await uploadQuestionsToDrive(filteredList, token, source);
    if (success === "success") {
      const quizName = quizData.name;
      const students = quizData.student_name.join("-");
      const questions = quizData.questions;
      const subject = quizData.subject[0];

      setMessage("Exporting To ClassMarker...");
      const classmarkerResponse = await exportQuestionsToClassMarker(
        questions,
        students,
        subject,
        quizName,
        id,
        token,
        source
      );
      // then mark quiz as exported
      if (classmarkerResponse === "success") {
        setMessage("Marking Quiz As Exported...");
        const quiz = await markQuizAsExported(id, token);
        if (quiz.length > 0) {
          setQuizMeta({
            exporedAt: quiz[0].exported_at,
            classmarkerName: quiz[0].classmarker_category,
            subject: quiz[0].subject,
            difficulty: quiz[0].difficulty,
            topic: quiz[0].topic,
            chapter: quiz[0].chapter,
            max_attempt_time_in_minutes: quiz[0].max_attempt_time_in_minutes,
          });
          setQuizData(quiz[0]);
        }
      } else if (classmarkerResponse === "cancelled") {
        setSnackBar(true);
        setSnackbarMessage("Export Request Cancelled");
      } else if (classmarkerResponse === "error") {
        setSnackBar(true);
        setSnackbarMessage("Some error occured. Contact Admin.");
      }
    } else if (success === "cancelled") {
      setSnackBar(true);
      setSnackbarMessage("Export Request Cancelled");
    }
    setMessage("");
    handleClose();
  };

  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled by the user.");
      setCancelTokenSource(null);
    }
  };

  const handleSelectStudent = (e) => {
    const selectedValue = e.target.value;
    setSelectedStudent(selectedValue);
  };

  useEffect(() => {
    if (selectStudent) {
      const filteredQuizData = {
        ...quizData,
        classmarker_user_response: quizData.classmarker_user_response.filter(
          (item) => item.classmarker_result_id === selectStudent
        ),
      };

      setSingleQuiz(filteredQuizData);
    }
  }, [selectStudent, quizData, questionDetails]);

  if (isLoading) {
    return (
      <span>
        <Toolbar />
        <CircularProgress />
      </span>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={snackbar}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message={snackBarMessage}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={backDrop}
      >
        <CircularProgress
          // variant="determinate"
          color="inherit"
        />
        <Typography>Please do not press back or close this tab..</Typography>
        <Typography>{message}</Typography>

        <Button
          size="small"
          variant="contained"
          onClick={handleCancel}
          sx={{
            color: 'black',
            backgroundColor: (theme) => theme.palette.secondary.main,
            ":hover": {
              backgroundColor: (theme) => theme.palette.secondary.dark,
            },
          }}
          disabled={cancelTokenSource ? false : true}
        >
          Cancel Request
        </Button>
      </Backdrop>

      <FixedSideNavigation value={value} setValue={setValue} meta={quizMeta} />

      <Grid
        container
        component="main"
        sx={{
          padding: "2vh",
          flexGrow: 0,
          width: "80vw",
          p: 3,
        }}
      >
        <Toolbar />
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "2vh",
          }}
        >
          <Grid item md={5} sm={8} xs={12}>
            <Box>
              <Stack direction={"row"} spacing={5}>
                {quizData.exported ? (
                  <span>
                    <Button variant="outlined" size="small" disabled={true}>
                      Quiz Exported
                    </Button>
                  </span>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={exportQuestions}
                  >
                    Export To Classmaker
                  </Button>
                )}

                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={buttonLoading}
                    onClick={downloadPaper}
                  >
                    Download Paper
                  </Button>
                  {buttonLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>

                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={buttonLoading}
                    onClick={downloadSolution}
                  >
                    Download Answer Key
                  </Button>
                  {buttonLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item md={5} sm={8} xs={12}>
            <Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {quizData.name}
                </Box>
                <Box
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {questionDetails.length} Questions
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isLoading && value === 0 && (
            <Grid item md={5} sm={8} xs={12}>
              {quizData.student_name && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography>{quizData.student_name.join(", ")}</Typography>
                </Box>
              )}

              {questionDetails.map((questionData, index) => (
                <Box key={`${questionData["id"]}-question-selected`}>
                  <ProblemStatement
                    id={questionData["id"]}
                    data={questionData}
                    key={questionData["id"]}
                    displayQuestion={true}
                    displayQuestionId={true}
                    displaySolution={false}
                    displayAnswerKey={true}
                    displayOptions={true}
                    displayAddToQuiz={false}
                    displayRemoveFromQuiz={false}
                    displayConcepts={true}
                    index={index + 1}
                    removeQuizDetails={false}
                  />
                  <Divider style={{ backgroundColor: "black" }} />
                </Box>
              ))}
            </Grid>
          )}

          {!isLoading && value === 1 && (
            <Grid item md={5} sm={8} xs={12}>
              <span>SUMMARY</span>
            </Grid>
          )}

          {!isLoading && value === 2 && (
            <Grid item md={5} sm={8} xs={12}>
              {questionDetails.map((questionData, index) => (
                <Box key={`${questionData["id"]}-solution-selected`}>
                  <ProblemStatement
                    id={questionData["id"]}
                    data={questionData}
                    key={`${questionData["id"]}-question`}
                    displayQuestion={true}
                    displayQuestionId={false}
                    displaySolution={false}
                    displayAnswerKey={true}
                    displayOptions={false}
                    displayAddToQuiz={false}
                    displayRemoveFromQuiz={false}
                    removeQuizDetails={false}
                    displayConcepts={false}
                    index={index + 1}
                  />
                  <ErrorBoundary>
                    <Solution
                      key={questionData["id"]}
                      data={questionData}
                      id={questionData["id"]}
                    />
                  </ErrorBoundary>
                  <Divider style={{ backgroundColor: "black" }} />
                </Box>
              ))}
            </Grid>
          )}

          {!isLoading && value === 3 && (
            <div style={{ width: "100%", overflowX: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                <label
                  htmlFor="quiz_students"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "#333",
                  }}
                >
                  Quiz Students
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <select
                  value={selectStudent || ""}
                  onChange={handleSelectStudent}
                  style={{
                    padding: "8px 12px",
                    borderRadius: "4px",
                    border: "1px solid black",
                    fontSize: "16px",
                    color: "#333",
                    cursor: "pointer",
                  }}
                >
                  <option value="">Select a student</option>
                  {quizData.quiz_students?.map((item) => (
                    <option
                      key={item.classmarker_result_id}
                      value={item.classmarker_result_id}
                    >
                      {item.first_name}
                    </option>
                  ))}
                </select>
              </div>

              {!selectStudent && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ fontSize: "25px" }}>
                    Select Student to review Responses
                  </h2>
                </div>
              )}

              {selectStudent &&
                questionDetails.map((questionData, index) => (
                  <div
                    key={`${questionData.id}-container`}
                    style={{
                      margin: "0 0 20px 0",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Row 1: Question and Solution */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        height: "50vh",
                        flexWrap: "nowrap",
                      }}
                    >
                      {/* Column 1: Problem Statement */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <ProblemStatement
                          id={questionData.id}
                          data={questionData}
                          displayQuestion={true}
                          displayQuestionId={false}
                          displaySolution={false}
                          displayOptions={false}
                          displayAddToQuiz={false}
                          displayRemoveFromQuiz={false}
                          removeQuizDetails={false}
                          displayConcepts={false}
                          index={index + 1}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                          }}
                        />
                      </div>

                      {/* Column 2: Solution */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.2)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <ErrorBoundary>
                          <Solution
                            key={questionData.id}
                            data={questionData}
                            id={questionData.id}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "100%",
                              height: "auto",
                              objectFit: "contain",
                            }}
                          />
                        </ErrorBoundary>
                      </div>
                    </div>

                    {/* Row 2: Correct Answer and User Response */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        flexWrap: "nowrap",
                      }}
                    >
                      {/* Column 1: Answer Key */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        <AnswerKey
                          answerKey={questionData.answer_key}
                          questionType={questionData.question_type}
                          source={questionData.source}
                          id={questionData.id}
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>

                      {/* Column 2: User Response */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          User response:&nbsp;
                        </span>
                        {singleQuiz.classmarker_user_response &&
                        singleQuiz.classmarker_user_response.length > 0 ? (
                          singleQuiz.classmarker_user_response.filter(
                            (x) =>
                              x.classmarker_id === questionData.classmarker_id
                          ).length > 0 ? (
                            singleQuiz.classmarker_user_response
                              .filter(
                                (x) =>
                                  x.classmarker_id ===
                                  questionData.classmarker_id
                              )
                              .map((filteredResponse) => (
                                <span key={filteredResponse.classmarker_id}>
                                  {filteredResponse.user_response ||
                                    "No response"}
                                </span>
                              ))
                          ) : (
                            <span>Not Present</span>
                          )
                        ) : (
                          <span>Not Present</span>
                        )}
                      </div>
                    </div>

                    {/* Row 3: Metadata and Notes */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        flexWrap: "nowrap",
                      }}
                    >
                      {/* Column 1: Question Metadata */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <p>
                            <strong>Question Metadata</strong>
                          </p>
                          <p>Type: {questionData.question_type}</p>
                        </div>
                      </div>

                      {/* Column 2: Notes */}
                      <div
                        style={{
                          flex: "1 1 50%",
                          minWidth: "200px",
                          borderRadius: "8px",
                          boxShadow: "0 5px 4px rgba(0, 0, 0, 0.2)",
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <label htmlFor={`notes-${questionData.id}`}>
                            <strong>Notes:</strong>
                          </label>
                          <textarea
                            id={`notes-${questionData.id}`}
                            rows="4"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              borderRadius: "8px",
                              paddingLeft: "5px",
                            }}
                            placeholder="Add your notes here..."
                          ></textarea>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "10px 0",
                            justifyContent: "center",
                            gap: "20px",
                          }}
                        >
                          <Button style={{ border: "1px solid gray" }}>
                            Save
                          </Button>
                          <Button style={{ border: "1px solid gray" }}>
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>

                    <hr
                      style={{
                        border: "2px solid black",
                        height: "2px",
                        width: "100%",
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizViewer;
