const AnswerKey = ({answerKey, questionType, source, id}) => {
    const optionMap = {
        0: 'A', 1: 'B', 2: 'C', 3: 'D'
    }

    const parseAnswerKey = (answerKey, questionType, source) => {
        if (source==='ideaboard') {
            if (questionType === 'Numeric answer') {
                return JSON.parse(answerKey.replace(/^\['|'\]$/g, ''))
            } else if (questionType === 'Multiple choice question, More than one correct choice among multiple options') {
                return JSON.parse(answerKey).map(answer => optionMap[answer-1]).join(', ')
            } else {
                console.log(questionType)
                console.log(answerKey)
                console.log(id)
                return 'Not Parsed. Check Browser Logs'
            }
        } else if (source === 'college-doors') {
            if (questionType === 'Multiple choice question, Single correct out of 4 options') {
                return optionMap[answerKey]
            } else if (questionType === 'Numeric answer'){
                return JSON.parse(answerKey.replace(/'/g, '"')).join(',  ')
            } else if(questionType === 'Multiple choice question, More than one correct choice among multiple options') {
                return JSON.parse(answerKey).map(answer => optionMap[answer]).join(', ')
            } else if(questionType === 'Match columns in a table with more than one row match is possible') {
                return Object.entries(JSON.parse(answerKey.replace(/'/g, '"'))).map(([key, value]) => {
                    return `${key}: ${value.join(', ')}`
                }).join(' // ')
            } else if (questionType === 'Single option is correct out of 4 options, with multiple questions based on a 3*4 (or m*n) table') {
                return optionMap[answerKey]
            } else {
                // console.log(id, answerKey, questionType, source , options)
                console.log(questionType)
                console.log(answerKey)
                console.log(id)
                return 'Not Parsed. Check Browser Logs'
            }
        } else if (source === 'exam-side') {
            if (questionType === 'Numerical') {
                return JSON.parse(answerKey.replace(/^\['|'\]$/g, ''))
            } else if (questionType === 'Single Select') {
                return optionMap[answerKey]
            } else if (questionType === 'Multi Select') {
                try {
                    return JSON.parse(answerKey).map(answer => optionMap[answer]).join(', ')
                } catch {
                    return optionMap[answerKey]
                }
            } else if (questionType === 'True / False') {
                return optionMap[answerKey]
            }else
                return answerKey
        }
    }

    return (
        <div style={{marginTop: '2vh', marginBottom: "2vh"}}>
            <span>
                <b>Correct Answer:</b>
            </span>
            <span style={{marginLeft: '2vw'}}>
                <span>{parseAnswerKey(answerKey, questionType, source)}</span>
            </span>
        </div>
    )
}

export default AnswerKey